import React from 'react'
import NoResult from '../../../assets/no-informations.svg'
import { Grid, Paper } from '@mui/material'
import { BLACK } from '../../ui/Colors'
import { PrimaryText, Title } from '../../ui/Text'

export const MaintenanceScreen = () => {
    return (
        <Paper
            sx={{
                width: 'calc(100% - 48px)',
                height: '100vh',
                margin: 'auto',
                marginTop: '1vw',
            }}
        >
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                height={'100%'}
                wrap="nowrap"
            >
                <img
                    alt="Chargement"
                    src={NoResult}
                    style={{ width: '35vw' }}
                />
                <Title mt="3vh" >Maintenance</Title>
                <Grid
                    container
                    alignItems="center"
                    direction="column"
                    justifyContent="space-between"
                    height="15vh"
                >
                    <PrimaryText mt={3} color={BLACK}>
                        Cette page est actuellement en cours de réparation par nos équipes, elle revient très vite !
                    </PrimaryText>
                </Grid>
            </Grid>
        </Paper>
    )
}