import { Grid } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useAppDispatch } from '../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import { fetchAssortmentPromo, fetchCostPromo, fetchImpactPromo, fetchPerformancePromo, fetchPromoKPIs, fetchWarehousesPromo } from '../../../../redux/promotions/promotionsSlice'

export const PromoLayout = ({ children, filters }: { children: JSX.Element, filters: ActiveFsFilters }) => {
    const dispatch = useAppDispatch()

    const _fetchPromo = useCallback(
        (filters: ActiveFsFilters) => {
            dispatch(fetchPromoKPIs(filters))
            dispatch(fetchCostPromo(filters))
            dispatch(fetchPerformancePromo(filters))
            dispatch(fetchImpactPromo(filters))
            dispatch(fetchAssortmentPromo(filters))
            dispatch(fetchWarehousesPromo(filters))
        },
        [dispatch]
    )

    useEffect(() => {
        if (filters.startDate) {
            _fetchPromo(filters)
        }
    }, [filters])

    return <Grid>{children}</Grid>
}